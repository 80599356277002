import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUserId } from "../utils/store";

Vue.use(VueRouter)
const routes = [{
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/login/index.vue'),
        meta: {
            requiresAuth: false,
            title: '登录'
        },
    },
    {
        path: '/systemLogin',
        name: 'Login',
        component: () =>
            import ('../views/login/loginByAdmin.vue'),
        meta: {
            requiresAuth: false,
            title: '登录'
        },
    },
    {
        path: '/',
        redirect: '/home',
        meta: {
            requiresAuth: true,
            title: '首页'
        },
    },
    {
        path: '/show-pdf',
        name: 'showPdf',
        component: () =>
            import ('../views/session/showPdf.vue'),
        meta: {
            requiresAuth: false,
            title: 'PPT展示'
        },
    },
    {
        path: '/guest/sessions',
        name: 'guestSession',
        component: () =>
            import ('../views/guestPage/index.vue'),
        meta: {
            requiresAuth: true,
            title: '嘉宾日程'
        },
    },

    {
        path: '/guest/messageList',
        name: 'messageList',
        component: () =>
            import ('../views/guestPage/messageList.vue'),
        meta: {
            requiresAuth: true,
            title: '咨询消息'
        },
    },

    {
        path: '/session/pptList',
        name: 'pptList',
        component: () =>
            import ('../views/session/pptList.vue'),
        meta: {
            requiresAuth: true,
            title: 'pptList'
        },
    },
    {
        path: '/session/pdf',
        name: 'pdf',
        component: () =>
            import ('../views/session/pdf.vue'),
        meta: {
            requiresAuth: true,
            title: 'PDF'
        },
    },
    {
        path: '/home',
        redirect: '/home/organizers',
        component: () =>
            import ('../views/Home'),
        meta: {
            requiresAuth: true,
            title: '首页'
        },
        children: [
            {
                path: 'organizers',
                name: 'index',
                component: () =>
                    import ('../views/organizer/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Organizer'
                },
            },
            {
                path: 'organizer-settings',
                name: 'index',
                component: () =>
                    import ('../views/organizerSetting'),
                meta: {
                    requiresAuth: true,
                    title: 'Organizer'
                },
            },
            {
                path: 'guests',
                name: 'index',
                component: () =>
                    import ('../views/guests'),
                meta: {
                    requiresAuth: true,
                    title: '嘉宾列表'
                },
            },
            {
                path: 'orders',
                name: 'index',
                component: () =>
                    import ('../views/orders'),
                meta: {
                    requiresAuth: true,
                    title: '订单列表'
                },
            },
            {
                path: 'agreement',
                name: 'agreement',
                component: () =>
                    import ('../views/agreement'),
                meta: {
                    requiresAuth: true,
                    title: '协议列表'
                },
            },
            {
                path: 'brandparty',
                name: 'index',
                component: () =>
                    import ('../views/brandParty'),
                meta: {
                    requiresAuth: true,
                    title: '品牌方列表'
                },
            },
            {
                path: 'products',
                name: 'index',
                component: () =>
                    import ('../views/product/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Products'
                },
            },
            {
                path: 'tabsList',
                name: 'tabsList',
                component: () =>
                    import ('../views/tabsList/index.vue'),
            },
            {
                path: 'meetings',
                name: 'index',
                component: () =>
                    import ('../views/meeting/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Mettings'
                },
            },
            {
                path: 'partner_meetings',
                name: 'index',
                component: () =>
                    import ('../views/meeting/partnerMeetings.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Meetings'
                },
            },
            {
                path: 'session/ppt',
                name: 'ppt',
                component: () =>
                    import ('../views/session/ppt.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'PPT'
                },
            },
        ]
    },
    {
        path: '/meeting',
        component: () =>
            import ('../views/meetingLandingPage/index.vue'),
        children: [{
                path: 'edit',
                name: 'index',
                component: () =>
                    import ('../views/meetingLandingPage/edit/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '会议编辑'
                },
            },
            {
                path: 'session',
                name: 'index',
                component: () =>
                    import ('../views/session/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '日程列表'
                },
            },

            {
                path: 'fileManage',
                name: 'meetingFileManage',
                component: () =>
                    import ('../views/meeting/fileManage/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '文件管理'
                },
            },
            {
                path: 'sessionGroup',
                name: 'index',
                component: () =>
                    import ('../views/sessionGroup/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '日程组'
                },
            },
            {
                path: 'guests',
                name: 'index',
                component: () =>
                    import ('../views/meeting/meetingGuests.vue'),
                meta: {
                    requiresAuth: true,
                    title: '会议嘉宾'
                },
            },
            {
                path: 'rooms',
                name: 'index',
                component: () =>
                    import ('../views/meetingRoom/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '会议房间'
                },
            },
            {
                path: 'tickets',
                name: 'index',
                component: () =>
                    import ('../views/ticket/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '门票'
                },
            },
            {
                path: 'partners',
                name: 'index',
                component: () =>
                    import ('../views/meetingLandingPage/partners/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: "品牌客户"
                },
            },
            {
                path: 'sponsorContents',
                name: 'index',
                component: () =>
                    import ('../views/sponsorContents/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '赞助产品'
                },
            },
            {
                path: 'exhibitors',
                name: 'index',
                component: () =>
                    import ('../views/exhibitor/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '会议展商'
                },
            },
            {
                path: 'partner-product',
                name: 'index',
                component: () =>
                    import ('../views/partnerProduct/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '会议展商'
                },
            },
            {
                path: 'contacts',
                name: 'index',
                component: () =>
                    import ('../views/contacts/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '联系方式'
                },
            },
            {
                path: 'remind',
                name: 'index',
                component: () =>
                    import ('../views/meetingLandingPage/remind/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '通知设置'
                },
            },
            /* {
                 path: 'consulting-statistics',
                 name: 'meetingConsultingStatistics',
                 component: () => import ('../views/meetingLandingPage/consultingStatistics/index.vue'),
                 meta: {
                     requiresAuth: true,
                     title: '咨询统计'
                 },
             },*/
            {
                path: 'statistics',
                name: 'index',
                component: () =>
                    import ('../views/statistics/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: "咨询统计"
                },
            },
            {
                path: 'session/guests',
                name: 'index',
                component: () =>
                    import ('../views/session/guests/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '会议嘉宾'
                },
            },
            {
                path: 'session/pptList',
                name: 'pptList',
                component: () =>
                    import ('../views/session/pptList.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'pptList'
                },
            },
            {
                path: 'sessionGroup/guests',
                name: 'index',
                component: () =>
                    import ('../views/sessionGroup/guests/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '会议嘉宾'
                },
            },
            {
                path: 'participants',
                name: 'index',
                component: () =>
                    import ('../views/participants/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '与会人员'
                },
            },
            {
                path: 'message',
                name: 'index',
                component: () =>
                    import ('../views/kefuMessage/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '客服消息'
                },
            },
            {
                path: 'handbooks',
                name: 'index',
                component: () =>
                    import ('../views/handbook/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: '会议手册'
                },
            },
            {
                path: 'pptAttachments',
                name: 'test',
                component: () =>
                    import ('../views/session/pptAttachments.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'test'
                },
            },
            {
                path: 'statistics',
                name: 'index',
                component: () =>
                    import ('../views/statistics/index.vue'),
                meta: {
                    requiresAuth: true,
                    title: "咨询统计"
                },
            }
        ]

    }
]
const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        var userId = getUserId()
        if (to.meta.requiresAuth && (userId == undefined || userId == '')) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router