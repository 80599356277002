import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import router from './router'
import UserData from './utils/UserData'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.prototype.$meeting = new Vue()
    // Vue.prototype.$headerVue = new Vue()

import dayjs from "dayjs"

Vue.prototype.$dayjs = dayjs
Vue.use(VueQuillEditor)
Vue.prototype.$UserData = UserData
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')