import Cookies from 'js-cookie'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const USER_ID = 'canhaohui-user_id'
const USER_NAME = 'canhaohui-user_name'
const USER_TYPE = 'canhaohui-user_type'

const TAB_MEETING = 'canhaohui-meeting-tab-index'

// const HEADER_TYPE = 'canhaohui-headerType'

export function setMeetingTabIndex(index) {
    Cookies.set(TAB_MEETING, index)
}

export function getMeetingTabIndex() {
    return Cookies.get(TAB_MEETING)
}

export function setUserType(type) {
    Cookies.set(USER_TYPE, type)
}
export function getUserType() {
    return Cookies.get(USER_TYPE)
}

export function removeUserType() {
    Cookies.remove(USER_TYPE)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}
export function getUserId() {
    return Cookies.get(USER_ID)
}
export function removeUserId() {
    Cookies.remove(USER_ID)
}
export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name)
}
export function getUserName() {
    return Cookies.get(USER_NAME)
}
export function removeUserName() {
    Cookies.remove(USER_NAME)
}

export function logOut() {
    removeUserId()
    removeUserName()
    removeUserType()
}