<template>
  <div id="app" style="width: 100%; margin: 0; padding: 0">
    <header v-if="showHeaderType == 0" class="app-head">
      <div style="flex: 1">
        <img src="./assets/nav_logo.png" style="
            width: 140px;
            height: auto;
            object-fit: cover;
            margin-top: 12px;
          " />
      </div>
      <el-dropdown style="display: flex;align-items: center;">
        <img src="./assets/icon_settings.png" style="width: 18px;height: 18px;margin-right: 8px;object-fit: cover;">
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="user_type == 'organizer'">
            <div @click="gotoOrganizerSettingView()">组织信息</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="logout()">退出</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span style="color: #333;">{{ this.$UserData.user_name }}</span>
    </header>
    <div :class="(showHeaderType == 0) ? 'content-with-head' : 'content-no-head'">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { getUserName, getUserType, logOut } from "./utils/store";

export default {
  name: "app",
  data() {
    return {
      user_type:getUserType(),
      showHeaderType: 0 //0 ：显示  1、不显示 ，2、强制不显示
    };
  },
  created() {
    // this.showHeaderType = getHeaderType()
    // if (!this.showHeaderType) {
    //   this.showHeaderType = 0
    // }
  },
  watch: {
    $route: {
      handler(newRouter) {
        let path = newRouter.path
        var show = !(path.search("login") >= 0 || path.search("systemLogin") >= 0 || path.search("show-pdf") >= 0)

        if (path.startsWith('/meeting/')) {
          show = false
        }
        if (show) {
          this.showHeaderType = 0
        } else {
          this.showHeaderType = 1
        }
      },
      immediate: true
    }
  },
  mounted() {
    console.log('测试自动部署')
    this.$UserData.user_name = getUserName();
  },
  methods: {

    gotoOrganizerSettingView(){
      this.$router.push({
        path:'/home/organizer-settings'
      })
    },

    logout() {
      let user_type = getUserType();
      logOut();
      if (user_type == "admin") {
        this.$router.replace("/systemLogin");
      } else {
        this.$router.replace("/login");
      }
      // location.reload()
    },
  },
};
</script>

<style>
.app-head {
  padding: 0px 20px 0px 60px;
  box-sizing: border-box;
  display: flex;
  height: 64px;
  position: relative;
  font-size: 12px;
  background-color: white;
  color: white;
  line-height: 64px;
}

.content-no-head {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.content-with-head {
  width: 100%;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.el-main {
  margin: 0px;
  padding: 0px;
}

.el-container {
  margin: 0px;
  padding: 0px;
}

* {
  padding: 0;
  margin: 0;
}
</style>
